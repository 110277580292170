:root {
  --my-black: #030a13;
  --my-dark-blue: #081930;
  --my-light-blue: #5c9ab1;
  --my-blue: #42729f;
  --my-red: hsl(8, 51%, 45%);
  --my-green: #5fa65f;
  --my-shadow: #254560;
  --toggle-handle-width: 25px;
  --panel-open-width: 33vw;
  --panel-move-time: 0.75s;
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--my-black);
  overscroll-behavior: contain;
  position: fixed;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




a:link {
  color: var(--my-light-blue);
  text-decoration: none;
}

a:visited {
  color: var(--my-light-blue);
  text-decoration: none;
}

a:hover {
  color: #68b0ca ;
}

a:active {
  color: var(--my-shadow);
}




.main {
  position: fixed;
  display: flex;
  width: 100vw;
  flex-direction: row;
  overflow: hidden;
  left: 0;
  top: 0;
  font-size: 15px;
}

.button{
  cursor: pointer;
  height: auto;
  width: auto;
  padding: 2px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 5px;
  background-color: var(--my-light-blue);
  color: var(--my-black);
  border: none;
  border-radius: 4px;
  font-size: 1em;
  font-weight: 600;
  user-select: none;
}



.panel, .panel-hidden {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  position: relative;
  color: var(--my-light-blue);
  text-align: center;
  z-index: 21;
  overflow: hidden;
  flex-direction: row;
  height: 100%;
  width: var(--panel-open-width);
}

.panel {
  left: 0;
  transition: left var(--panel-move-time) ease-in-out;
}

.panel-hidden {
  left: calc(var(--toggle-handle-width) - var(--panel-open-width));
  transition: left var(--panel-move-time) ease-in-out;
}




.navbar {
  top: 0px;
  width: calc(var(--panel-open-width) - var(--toggle-handle-width));
  background-color: var(--my-dark-blue);
  display: flex;
  align-items: center;
  direction: column;
  z-index: 20;
  user-select: none;
  height: auto;
}

.nav-container {
  display: flex;
  align-items: center;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
}

.nav-links {
  /* font-size: 1em; */
  color: var(--my-light-blue);
  text-decoration: none;
  height: 100%;
}

.nav-item {
  line-height: 100%;
  margin-left: 20px;
  white-space: nowrap;
  margin-top: 10px;
}

.nav-item:after {
  position: relative;
  top: 2.5px;
  content: "";
  display: block;
  height: 0.95vh;
  width: 0;
  background: transparent;
  transition: width 0.7s ease-in-out, background-color 0.5s ease-in-out;
}

.nav-item:hover:after {
  width: 100%;
  background: var(--my-light-blue);
}

.nav-item .active {
  color: var(--my-light-blue);
  border-bottom: 1vh solid var(--my-light-blue);
}



.share-button {
  font-size: var(--toggle-handle-width);
  position: absolute;
  right: calc(var(--toggle-handle-width));
  top: 0;
  margin-top: 5px;
  margin-right: 60px;
  cursor: pointer;
}

.share-close{
  font-size: 2em;
  cursor: pointer;
}



.share-panel, .share-hidden{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 110px;
  transition:  height 0.5s;
  overflow: hidden;
  margin-top: 10px;
}

.share-hidden{
  height: 0px;
  /* visibility: hidden; */
  transition:  height 0.5s;
}

.share-buttons{
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin-top: 5px;
}


.copy-button {
  
  font-size: 2em;
  margin-top: 6px;
}

.message{
  text-align: center;
  font-size: 1.3em;
  text-indent: 0px;
}

.message-hidden{
  display: none;
}

.fullscreen{
  font-size: var(--toggle-handle-width);
  position: absolute;
  right: calc(var(--toggle-handle-width));
  top: 0;
  margin-top: 5px;
  margin-right: 20px;
  cursor: pointer;
}




.pages{
  
  color: var(--my-light-blue);
  text-align: center;
  flex-basis: 100%;
  z-index: 21;
  overflow: hidden;
}

.page-scroll-container {
  overflow-y: scroll;
  width: 100%;
  height: 95%;
  background-color: var(--my-black);
  
}

.page-scroll-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--my-black);
	-webkit-box-shadow: inset 0 0 6px var(--my-black);
	border-radius: 10px;
	background-color: var(--my-dark-blue);
}

.page-scroll-container::-webkit-scrollbar {
	width: 12px;
	background-color: var(--my-dark-blue);
}

.page-scroll-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px var(--my-black);
  box-shadow: inset 0 0 6px var(--my-black);
	background-color: var(--my-light-blue);
}





.games-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}


.filter{
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.filter-pane, .filter-pane-hidden{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  max-height: 4000px;
  transition: max-height 2s;
  background-color: var(--my-dark-blue);
  margin: 7px;
  border-radius: 8px;
}
.filter-pane-hidden{
  max-height: 0px;
  transition: max-height 0.5s;
  overflow: hidden;
}

.filter-arrow{
  margin-left: 5px;
}

.filter-legend{
  width: 70%;
  height: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  user-select: none;
}

.click-tags{
  font-size: 0.66em;
}

.filter-include, .filter-require, .filter-exclude{
  height: auto;
  width: 80px;
  color: var(--my-black);
  border-radius: 4px; 
  margin: 3px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.6em;
  padding-bottom: 2px;
}

.filter-tags{
  display: flex;
  justify-content: center;
  width: 90%;
  height: auto;
  border: solid 1px var(--my-shadow);
  border-radius: 4px;;
  padding: 5px;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.filter-tag{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: var(--my-black);
  background-color: var(--my-light-blue);
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 4px;
  font-size: 1em;
  user-select: none;
}

.filter-include, .filter-included{
 
  background-color: var(--my-blue); 
}

.filter-require, .filter-required{
  
  background-color: var(--my-green);
}

.filter-exclude, .filter-excluded{
  background-color: var(--my-red);
}

.filter-count{
  width: 80%;
  margin-bottom: 5px;
}




.game-choice-full{
  position: relative;
  width: 135px;
  height: auto;
  display: flex;
  flex-direction: column;
  border: outset 3px var(--my-shadow);
  background-color: var(--my-dark-blue);
  margin: 5px;
  align-items: center;
}

.game-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.favorite, .favorite-chosen {
  position: absolute;
  right: 3px;
  bottom: 3px;
  color: var(--my-dark-blue);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--my-light-blue);
}

.favorite-chosen {
  color: #AAAA00;
}

.game-title {
  text-align: center;
  text-shadow: -1px 1px var(--my-black);
}

.game-image-div {
  height: 80px;
  width: 60%;
  background-color: var(--my-black);
  margin-top: 5px;
  margin-bottom: 5px;
}

.game-image {
  height: 100%;
  width: 100%;
  background-color: var(--my-black);
  border-radius: 5px;
  margin: 0px; padding: 0px;
} 

.game-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-indent: 0px;
}

.toggle-info{
  position: absolute;
  bottom: 3px;
  left: 6px;
}

.toggle-info:hover{
  color: var(--my-blue);
}

.game-description {
  text-align: center;
  font-size: 0.6em;
  width: 75%;
  height: 30px;
}

.game-details, .game-details-hidden{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  word-wrap: break-word;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
}

.game-details-hidden{
  display: none;
}

.game-tags{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  height: auto;
  
}

.game-tag{
  cursor: pointer;
  color: var(--my-black);
  background-color: var(--my-blue);
  border-radius: 2px;
  padding-left: 2px;
  padding-right: 2px;
  margin: 2px;
  font-size: 0.6em;
  user-select: none;
}

.external-link{
  margin-top: 10px;
  font-size: 0.9em;
  user-select: none;
}





.custom{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

.custom-input{
  width: 60%;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}






.info {
  text-align: justify;
  justify-content: center;
  align-items: center;
  align-self: center;
  
  text-indent: 20px;
  margin: 20px;
  margin-top: 40px;
}

.dev-notice {
  text-align: center;
  color: red;
  margin-top:20px;
}

.feedback{
  width: 100%;
  height: auto;
  justify-content: center;
  align-self: center;
  align-items: center;
  border: solid 1px var(--my-shadow);
  
  text-align: center;
  
}

.feedback-prompt{
  text-align: justify;
  margin: 10px;
}

.feedback-form{
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: auto;
  justify-content: space-around;
  align-items: center;
  text-align: center;
 
}

.text-input {
  margin: 10px;
  background-color: var(--my-shadow);
  color: var(--my-light-blue);
  font-size: 1.3em;
  
}

.feedback-name, .feedback-email {
  width: 60%;  
  height: 40px;
  text-align: center;
}

.feedback-message{
  width: 80%;
  height: 30vh;
  resize: none;
  text-align: justify;
}



.logo {
  height: 25px;
  display: none;
}

.logo-vert {
  width: 60%;
  margin: 0;
  margin-top: 10px;
}

.mobile {
  display: none;
}

.toggle-handle {
  display: block;
  font-size: var(--toggle-handle-width);
  width: var(--toggle-handle-width);
  background-color: var(--my-dark-blue);
  color: var(--my-light-blue);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 4px;
}





.gameframe, .gameframe-full {
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100vw - var(--panel-open-width));
  text-align: center;
  color: var(--my-light-blue);
  overflow: hidden;
  transition: width var(--panel-move-time) ease-in-out, left var(--panel-move-time) ease-in-out;
  left: 0;
}

.gameframe-full{
  left: calc(0vw - var(--panel-open-width) + var(--toggle-handle-width));
  width: 100vw;
  transition: width var(--panel-move-time) ease-in-out, left var(--panel-move-time) ease-in-out;
}






/*///////////////////////////   Mobile   //////////////////////////////////*/
@media screen and (max-width: 960px) {
  /* :root {
    --toggle-handle-width: 35px;
  } */

  .main {
    flex-direction: column;
  }
  
  .panel, .panel-hidden{
    
    text-align: center;   
    width: 100vw;
    
    flex-direction: column;
    left: 0;
  }
  
  .panel {
    top: 0;
    transition: top var(--panel-move-time);
  }
  
  .panel-hidden{
    top: calc(var(--toggle-handle-width) - 100%);
    transition: top var(--panel-move-time);
  }

  .gameframe, .gameframe-full {
    height: calc(100% - var(--toggle-handle-width));
    width: 100vw;
    transition: top var(--panel-move-time) ease-in-out;
    top: 0;
    left: 0;
  }
  
  .gameframe-full{
    top: calc(var(--toggle-handle-width) - 100%);
    transition: top var(--panel-move-time) ease-in-out;
  }

  .logo{
    height: 25px;
    display: block;
  }
  
  .logo-vert{
    display: none;
  }

  .pc {
    display: none
  }

  .mobile{
    display: block;
    height: 20px;
    font-size: 1.3em;
    margin-left:10px;
    
  }

  .fullscreen{
    right: 0;
    
  }

  .toggle-handle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 0;
    font-size: 1em;
    cursor: pointer;
    background-color: var(--my-dark-blue);
    color: var(--my-light-blue);
    height: 30px;
    width: 100%;
    z-index: 22 ;
  }
  
  .navbar{
    width: 100%;
  }
}